import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { DbInt8 } from '@pi/ridenour-essentials/zapatos.helpers';

import { DataService } from './data.service';
import type { StartSubscriptionCheckoutResponse } from '../interfaces/billing';

@Injectable({
    providedIn: 'root'
})
export class BillingService {
    protected readonly dataService = inject(DataService);

    private basePath = 'billing';

    public startSubscriptionCheckout(divisionId: DbInt8): Promise<StartSubscriptionCheckoutResponse> {
        return this.dataService.genericPOST(this.basePath + `/startSubscriptionCheckout?divisionId=${divisionId}`, {});
    }

    public finishSubscriptionCheckout(
        divisionId: DbInt8,
        paymentMethodId: string
    ): Promise<{ status: boolean; errors?: string }> {
        return this.dataService.genericPOST(
            this.basePath +
                '/finishSubscriptionCheckout' +
                `?divisionId=${divisionId}&paymentMethodId=${paymentMethodId}`,
            {}
        );
    }

    public cancelSubscription(divisionId: DbInt8): Promise<{ status: boolean; errors?: string }> {
        let params = new HttpParams();
        params = params.set('divisionId', divisionId);
        return this.dataService.genericDELETE(`${this.basePath}/subscription?${params.toString()}`);
    }
}
